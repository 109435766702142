import DeleteIcon from "@mui/icons-material/DeleteForever";
import UploadIcon from "@mui/icons-material/Publish";
import { Button, Fab, FormGroup, Grid } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles, useTheme } from "@mui/styles";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as applicationApi from "../../api/applicationApi";
var azure = require("azure-storage");
var stream = require("stream");
const { v4: uuidv4 } = require("uuid");
var arrayBuffer;
var fileReader = new FileReader();

const useStyles = makeStyles((theme) => ({
   formStyle: {
      textAlign: "center",
      height: "300px",
      width: "500px",
   },
}));

const FileUpload = (props) => {
   const classes = useStyles();
   const theme = useTheme();

   let [file, setFile] = useState(null);
   let [dragging, setDragging] = useState(false);
   let [dragCounter, setDragCounter] = useState(0);
   let [loading, setLoading] = useState(false);

   useEffect(() => {
      arrayBuffer = null;

      //add onload handler for file buffer
      fileReader.onload = function (event) {
         if (event.target.result !== null) {
            arrayBuffer = event.target.result;
         }

         setLoading(false);
      };

      //remove inputs on load of comp
      document.getElementById("fileInput").value = null;

      setFile(null);

      return () => {
         arrayBuffer = null;
      };
   }, [props]);

   function onChangeHandler(event) {
      try {
         fileReader.readAsArrayBuffer(event.target.files[0]);
         setFile(event.target.files[0]);
      } catch (err) {
         props.openAlertMessage("onChangeHandler File Upload alert: " + err, "error");
         console.error(err);
         setFile(null);
      }
   }

   function handleDrop(e) {
      try {
         e.preventDefault();
         e.stopPropagation();
         setDragging(false);

         if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            fileReader.readAsArrayBuffer(e.dataTransfer.files[0]);
            setFile(e.dataTransfer.files[0]);
            e.dataTransfer.clearData();
            setDragCounter(0);
         }
      } catch (err) {
         props.openAlertMessage("handleDrop File Upload alert: " + err, "error");
         console.error(err);
         setFile(null);
      }
   }

   function handleDrag(e) {
      try {
         e.preventDefault();
         e.stopPropagation();
      } catch (err) {
         props.openAlertMessage("handleDrag File Upload alert: " + err, "error");
         console.error(err);
         setFile(null);
      }
   }

   function handleDragIn(e) {
      try {
         e.preventDefault();
         e.stopPropagation();

         setDragCounter(dragCounter++);

         if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDragging(true);
         }
      } catch (err) {
         props.openAlertMessage("handleDragIn File Upload alert: " + err, "error");
         console.error(err);
         setDragging(false);
         setFile(null);
      }
   }

   function handleDragOut(e) {
      try {
         e.preventDefault();
         e.stopPropagation();

         setDragCounter(dragCounter--);

         if (dragCounter === 0) {
            setDragging(false);
         }
      } catch (err) {
         props.openAlertMessage("handleDragOut File Upload alert: " + err, "error");
         console.error(err);
         setDragging(false);
         setFile(null);
      }
   }

   function uploadFileToStorage() {
      setLoading(true);

      //initiate service
      applicationApi
         .getConnectionString()
         .then((resp) => {
            var queueService = azure.createQueueService(resp);
            var fileService = azure.createFileService(resp);

            applicationApi
               .getAzureShare()
               .then((respShare) => {
                  fileService.createShareIfNotExists(respShare, function (error, result, response) {
                     if (!error) {
                        // if result = true, share was created.
                        // if result = false, share already existed.
                     }
                  });

                  fileService.createDirectoryIfNotExists(
                     respShare,
                     props.directory,
                     function (error, result, response) {
                        if (!error) {
                           // if result.created = true, share was created.
                           // if result.created = false, share already existed.
                        }
                     },
                  );

                  var fileBuffer = Buffer.from(arrayBuffer);

                  var fileStream = new stream.Readable();
                  fileStream.push(fileBuffer);
                  fileStream.push(null);

                  var newFileName = uuidv4();
                  newFileName = newFileName + ".csv";

                  //need to create unique filename
                  var messageQueue = {
                     directory: props.directory,
                     userID: props.user._id,
                     termID: props.termID,
                     custID: props.custID,
                     fileName: newFileName,
                     originalFileName: file.name,
                  };

                  var encodedData = window.btoa(JSON.stringify(messageQueue)); // encode a string

                  fileService.createFileFromStream(
                     respShare,
                     props.directory,
                     newFileName,
                     fileStream,
                     fileBuffer.length,
                     function (error, result, response) {
                        if (!error) {
                           // file uploaded
                           //add message to queue
                           queueService.createMessage(respShare, encodedData, function (error) {
                              if (!error) {
                                 // Message inserted
                                 setFile(null);
                                 props.openAlertMessage("File Uploaded, Pending Import Task.", "success");
                                 setLoading(false);
                              }
                           });
                        }
                     },
                  );
               })
               .catch((err) => {
                  props.openAlertMessage("Error uploading file. Please try again.", "error");
                  console.error(err);
               });
         })
         .catch((err) => {
            props.openAlertMessage("Error uploading file. Please try again.", "error");
            console.error(err);
         });
   }

   return (
      <Grid item xs={2}>
         {file === null ? (
            <div
               className={classes.formStyle}
               onDragEnter={handleDragIn}
               onDragLeave={handleDragOut}
               onDragOver={handleDrag}
               onDrop={handleDrop}
            >
               {dragging ? (
                  <div
                     style={{
                        border: "dashed grey 4px",
                        backgroundColor: "rgba(255,255,255,.8)",
                        position: "absolute",
                        height: "16rem",
                        width: "30rem",
                        marginTop: "3rem",
                        zIndex: 9999,
                     }}
                  >
                     <div
                        style={{
                           position: "absolute",
                           top: "50%",
                           right: 0,
                           left: 0,
                           textAlign: "center",
                           color: "grey",
                           fontSize: 36,
                        }}
                     >
                        <div>drop here</div>
                     </div>
                  </div>
               ) : (
                  <div
                     style={{
                        top: "50%",
                        right: 0,
                        left: 0,
                        textAlign: "center",
                        color: "grey",
                        fontSize: 36,
                     }}
                  >
                     <label htmlFor="fileInput">
                        <input
                           style={{ display: "none" }}
                           id="fileInput"
                           name="fileInput"
                           accept=".csv"
                           type="file"
                           onChange={onChangeHandler}
                        />
                        <Tooltip title="Upload File" arrow>
                           <Fab color="primary" size="small" component="span" aria-label="add" variant="extended">
                              <UploadIcon />
                           </Fab>
                        </Tooltip>
                     </label>
                     <div>
                        <a>Or Drag Here</a>
                     </div>
                  </div>
               )}
            </div>
         ) : (
            <div className={classes.formStyle}>
               <FormGroup row={true}>
                  <div>
                     {loading ? (
                        <Button disabled variant="primary">
                           <UploadIcon /> Upload {file.name}
                        </Button>
                     ) : (
                        <Button onClick={uploadFileToStorage} variant="primary">
                           <UploadIcon /> Upload {file.name}
                        </Button>
                     )}
                  </div>
                  <div onClick={() => setFile(null)}>
                     <DeleteIcon />
                  </div>
               </FormGroup>
            </div>
         )}
      </Grid>
   );
};

function mapStateToProps(state, ownProps) {
   return {
      user: state.user.currentUser,
   };
}

FileUpload.propTypes = {
   open: PropTypes.bool.isRequired,
   custID: PropTypes.string,
   directory: PropTypes.string.isRequired,
   termID: PropTypes.string,
   user: PropTypes.object.isRequired,
   openAlertMessage: PropTypes.func.isRequired,
};

FileUpload.defaultProps = {
   open: false,
   directory: "",
   user: {},
   openAlertMessage: () => {
      return;
   },
};

export default connect(mapStateToProps)(FileUpload);
