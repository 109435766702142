import AssignmentIcon from "@mui/icons-material/Assignment";
import CloseIcon from "@mui/icons-material/Close";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import EvStationIcon from "@mui/icons-material/EvStation";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import RefreshIcon from "@mui/icons-material/Refresh";
import SaveIcon from "@mui/icons-material/Save";
import { AppBar, Box, Tab, Tabs, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import { green } from "@mui/material/colors";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import * as activityApi from "../../api/activityApi";
import * as customerApi from "../../api/customerApi";
import * as rateApi from "../../api/rateApi";
import * as userApi from "../../api/userApi";
import { dateFormatter } from "../../common/dateFormatter";
import { stateList } from "../../common/stateList.js";
import { timeFormatter } from "../../common/timeFormatter";
import { validateEmail } from "../../common/validateEmail";
import * as customerActions from "../../redux/actions/customerActions";
import "../../styles/truck.css";
import ActivityLogGrid from "../Activity/ActivityLogGrid";
import DeleteDialog from "../Delete/DeleteDialog";
import ExpireRatesDialog from "../ExpireRatesDialog/ExpireRatesDialog";
import ExtendRatesDialog from "../ExtendRatesDialog/ExtendRatesDialog";
import FileUpload from "../FileUpload/FileUpload";
import containerLogo from "../Logo/ContainerPort-Logo-white.png";
import StyledDataGrid from "../StyledDataGrid/StyledDataGrid";
import CustomerEmailInvite from "./CustomerEmailInvite";
import CustomerFuelTariff from "./CustomerFuelTariff";

const CustomerDetails = (props) => {
   const history = useHistory();
   const handleError = useErrorHandler();

   const [selectedCustomer, setSelectedCustomer] = useState(null);
   const [customerUsers, setCustomerUsers] = useState([]);
   const [gridLoading, setGridLoading] = useState(true);
   const [selectedUser, setSelectedUser] = useState(null);
   const [loading, setLoading] = useState(true);
   const [readOnly, setReadOnly] = useState(false);
   const [showAlert, setShowAlert] = useState(false);
   const [alertMessage, setAlertMessage] = useState(null);
   const [alertType, setAlertType] = useState("");
   const [loadActivity, setLoadActivity] = useState(true);
   const [loadNewtoken, setLoadNewToken] = useState(true);
   const [deleteModal, setDeleteModal] = useState(false);
   const [activityModal, setActivityModal] = useState(false);
   const [sortedCustToken, setSortedCustToken] = useState(null);
   const [customerEmailInviteModal, setCustomerEmailInviteModal] = useState(false);
   const [customerFuelTariffModal, setCustomerFuelTariffModal] = useState(false);
   const [exportingRates, setExportingRates] = useState(false);
   const [resendingInvite, setResendingInvite] = useState([]);
   const [revokingInvite, setRevokingInvite] = useState([]);
   const [tabsValue, setTabsValue] = useState(0);
   const [activityTableData, setActivityTableData] = useState([]);
   const [extendRatesModal, setExtendRatesModal] = useState(false);
   const [extendDate, setExtendDate] = useState(null);
   const [extendButtonDisabled, setExtendButtonDisabled] = useState(true);
   const [expireRatesModal, setExpireRatesModal] = useState(false);
   const [expireDate, setExpireDate] = useState(null);
   const [expireButtonDisabled, setExpireButtonDisabled] = useState(true);

   useEffect(() => {
      setLoading(true);
      setLoadNewToken(true);

      //retrieve record from API
      customerApi.getCustomer(props.match.params.id).then((resp) => {
         if (resp.customer.length > 0) {
            var newSelectedCustomer = resp.customer[0];
            setSelectedCustomer(newSelectedCustomer);
            setLoading(false);
         }
      });

      //retrieve record from API
      userApi.getUsers(null, props.match.params.id, false).then((resp) => {
         if (resp.users) {
            if (resp.users.length > 0) {
               var newUsersCustomerObject = _.cloneDeep(resp.users);
               if (newUsersCustomerObject) {
                  if (newUsersCustomerObject.length > 0) {
                     Object.keys(newUsersCustomerObject).forEach(function (key) {
                        newUsersCustomerObject[key].id = newUsersCustomerObject[key]._id;
                     });

                     setCustomerUsers(newUsersCustomerObject);
                     setGridLoading(false);
                     return;
                  }
               }
            }
         }

         setCustomerUsers([]);
         setGridLoading(false);
      });

      //retrieve invite records from API
      userApi.getCustomerInvites(props.match.params.id).then((resp) => {
         if (resp.invites) {
            if (resp.invites.length > 0) {
               var newCustomerInvitesObject = _.cloneDeep(resp.invites);
               if (newCustomerInvitesObject) {
                  if (newCustomerInvitesObject.length > 0) {
                     Object.keys(newCustomerInvitesObject).forEach(function (key) {
                        newCustomerInvitesObject[key].id = newCustomerInvitesObject[key]._id;
                     });

                     setSortedCustToken(_.orderBy(newCustomerInvitesObject, ["email"], ["asc"]));
                     return;
                  }
               }
            }
         }

         setSortedCustToken(null);
      });
   }, []);

   useEffect(() => {
      if (loadActivity) {
         activityApi
            .getActivity(null, null, null, [
               {
                  module: "CUSTOMER",
                  record_id: props.match.params.id,
                  rows: null,
               },
            ])
            .then((resp) => {
               //Build the tableData
               const newTableData = [];

               resp.activities.forEach((activityObjectResp) => {
                  activityObjectResp.activity.forEach((activity, i) => {
                     const newActivityObj = {};

                     newActivityObj["_id"] = activityObjectResp._id;
                     newActivityObj["id"] = `${activityObjectResp._id}-${i}`;
                     newActivityObj["date"] = dateFormatter(activityObjectResp.add_date);
                     newActivityObj["hour"] = timeFormatter(activityObjectResp.add_date);
                     newActivityObj["event"] = activity.event_code || "N/A";
                     newActivityObj["description"] = activity.event || "N/A";

                     newTableData.push(newActivityObj);
                  });
               });

               setActivityTableData(newTableData);
               setLoadActivity(false);
            })
            .catch((err) => {
               console.error(err);
            });
      }
   }, [loadActivity]);

   //load in security
   useEffect(() => {
      var curUserRoute = props.user.security.routes.filter((rt) => rt.module === "customermanagement");
      var userReadOnly = true;
      if (!props.user.security.admin) {
         switch (curUserRoute[0].permission) {
            case "readWrite":
               userReadOnly = false;
               break;
            case "readOnly":
               userReadOnly = true;
               break;
         }
      } else {
         userReadOnly = false;
      }
      setReadOnly(userReadOnly);
   }, [props.user]);

   //refresh invite token
   useEffect(() => {
      if (customerEmailInviteModal === false && loading === false) {
         customerApi.getCustomer(props.match.params.id).then((resp) => {
            if (resp.customer.length > 0) {
               var newSelectedCustomer = resp.customer[0];

               setSelectedCustomer(newSelectedCustomer);
            }
         });

         //retrieve invite records from API
         userApi.getCustomerInvites(props.match.params.id).then((resp) => {
            if (resp.invites) {
               if (resp.invites.length > 0) {
                  var newCustomerInvitesObject = _.cloneDeep(resp.invites);
                  if (newCustomerInvitesObject) {
                     if (newCustomerInvitesObject.length > 0) {
                        Object.keys(newCustomerInvitesObject).forEach(function (key) {
                           newCustomerInvitesObject[key].id = newCustomerInvitesObject[key]._id;
                        });

                        setSortedCustToken(_.orderBy(newCustomerInvitesObject, ["email"], ["asc"]));
                        return;
                     }
                  }
               }
            }

            setSortedCustToken(null);
         });

         setLoading(false);
         setLoadActivity(true);
      }
   }, [customerEmailInviteModal]);

   useEffect(() => {
      checkExtendButtonSecurity();
   }, [extendDate]);

   useEffect(() => {
      checkExpireButtonSecurity();
   }, [expireDate]);

   function onChange(event) {
      try {
         var newSelectedCustomer = _.cloneDeep(selectedCustomer);
         newSelectedCustomer[event.target.name] =
            event.target.type === "checkbox" ? event.target.checked : event.target.value;
         setSelectedCustomer(newSelectedCustomer);
      } catch (err) {
         handleError(err);
      }
   }

   function onChangeAddress(event) {
      try {
         var newSelectedCustomer = _.cloneDeep(selectedCustomer);
         newSelectedCustomer.address[event.target.name] = event.target.value;
         setSelectedCustomer(newSelectedCustomer);
      } catch (err) {
         handleError(err);
      }
   }

   function updateCust() {
      //validate name and code fields
      if (selectedCustomer.name.length === 0 || selectedCustomer.code.length === 0) return;

      if (selectedCustomer.code.indexOf(",") > -1) {
         openAlertMessage("Commas are not allowed in the Master Account", "error");
         return;
      }

      //validate PDF Email
      if (selectedCustomer.pdfEmail) {
         const validEmail = validateEmail(selectedCustomer.pdfEmail);
         if (validEmail.length > 0) {
            openAlertMessage(validEmail, "error");
            return;
         }
      }

      customerApi
         .saveCustomer(selectedCustomer)
         .then((resp) => {
            if (resp.success) {
               openAlertMessage("Customer Successfully Updated.", "success");
            } else {
               openAlertMessage(resp.message, "error");
            }

            props.getCustomers();
            setLoadActivity(true);
         })
         .catch((err) => {
            openAlertMessage(`Error saving customer: ${err}`, "error");
         });
   }

   function openAlertMessage(alertMessage, alertType) {
      setShowAlert(true);
      setAlertMessage(alertMessage);
      setAlertType(alertType);

      if (alertType === "success" || alertType === "warning") {
         setTimeout(function () {
            closeAlertMessage();
         }, 3000);
      }
   }

   function closeAlertMessage() {
      setShowAlert(false);
   }

   const useStyles = makeStyles((theme) => ({
      secondaryHeader: {
         borderBottom: "1px solid #2F3136",
         zIndex: "3",
         width: "100%",
      },
      layoutMain: {
         marginTop: "2rem",
         position: "fixed",
         width: "inherit",
         height: "100%",
      },
      layoutBody: {
         position: "absolute",
         height: "calc(100% - 9.5rem)",
         width: "100%",
         overflowY: "scroll",
      },
      layoutBody2: {
         position: "absolute",
         height: "calc(100% - 12.5rem)",
         width: "100%",
         overflowY: "scroll",
      },
      search: {
         position: "relative",
         borderRadius: theme.shape.borderRadius,
         border: "1px solid black",
         backgroundColor: "#ffff",
         marginRight: theme.spacing(2),
         marginLeft: 0,
         width: "30%",
         float: "right",
         [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto",
         },
      },
      searchIcon: {
         color: "black",
         padding: theme.spacing(0, 2),
         height: "100%",
         position: "absolute",
         pointerEvents: "none",
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
      },
      save: {
         float: "right",
         fontSize: "2rem",
         marginRight: ".25rem",
      },
      back: {
         color: "white",
         backgroundColor: "#2F3136",
         float: "right",
         marginRight: "1rem",
         "&:hover": {
            backgroundColor: "black",
         },
      },
      inputRoot: {},
      inputInput: {
         padding: theme.spacing(1, 1, 1, 0),
         paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
         transition: theme.transitions.create("width"),
         width: "100%",
         [theme.breakpoints.up("md")]: {
            width: "20ch",
         },
      },
      listDrawer: {
         width: "300px",
         maxWidth: 360,
         backgroundColor: theme.palette.background.paper,
      },
      appBar: {
         "& .MuiButtonBase-root": {
            padding: "1.25rem 2.25rem",
         },
         "& .MuiTabs-indicator": {
            height: "4px",
         },
      },
      buttonProgress: {
         color: green[500],
      },
      button: {
         color: "white",
         backgroundColor: "#002D72",
         marginTop: "1rem",
      },
      label: {
         flexDirection: "column",
         fontSize: "11px",
      },
      icon: {
         fontSize: "24px",
         marginBottom: theme.spacing.unit,
      },
   }));

   function handleEditClick(user) {
      try {
         history.push({ pathname: `/user/details/${user.row._id}` });
      } catch (err) {
         handleError(err);
      }
   }

   function handleEditSpecificAccessorials() {
      try {
         history.push({ pathname: `${props.match.url}/customer-specific-accessorials` });
      } catch (err) {
         handleError(err);
      }
   }

   const handleDeleteClick = (user) => {
      try {
         setSelectedUser(user.row);
         setDeleteModal(true);
      } catch (err) {
         handleError(err);
      }
   };

   function resendInvite(id, customerName, email) {
      let finalResending = [...resendingInvite];
      finalResending.push(id);
      setResendingInvite(finalResending);

      userApi
         .resendCustomerInvite(id, customerName)
         .then((resp) => {
            if (resp) {
               if (resp.success) {
                  setLoadActivity(true);

                  customerApi.getCustomer(props.match.params.id).then((resp) => {
                     if (resp.customer.length > 0) {
                        var newSelectedCustomer = resp.customer[0];
                        setSelectedCustomer(newSelectedCustomer);
                     }
                  });

                  //retrieve invite records from API
                  userApi.getCustomerInvites(props.match.params.id).then((resp) => {
                     if (resp.invites) {
                        if (resp.invites.length > 0) {
                           var newCustomerInvitesObject = _.cloneDeep(resp.invites);
                           if (newCustomerInvitesObject) {
                              if (newCustomerInvitesObject.length > 0) {
                                 Object.keys(newCustomerInvitesObject).forEach(function (key) {
                                    newCustomerInvitesObject[key].id = newCustomerInvitesObject[key]._id;
                                 });

                                 setSortedCustToken(_.orderBy(newCustomerInvitesObject, ["email"], ["asc"]));
                                 return;
                              }
                           }
                        }
                     }

                     setSortedCustToken(null);
                  });

                  openAlertMessage("Sent invite to addresses.", "success");
               } else {
                  openAlertMessage(`Error sending invite: ${resp.message}.`, "error");
               }
            } else {
               openAlertMessage(`Error sending invite: ${resp.message}.`, "error");
            }
         })
         .catch((err) => {
            openAlertMessage(`Error sending invite: ${err.message}.`, "error");
         });

      const resendingIndex = finalResending.indexOf(id);

      if (resendingIndex > -1) {
         finalResending = [...finalResending.slice(resendingIndex, -1)];
      }

      setResendingInvite(finalResending);

      customerApi.getCustomer(props.match.params.id).then((resp) => {
         if (resp.customer.length > 0) {
            var newSelectedCustomer = resp.customer[0];
            setSelectedCustomer(newSelectedCustomer);
         }
      });

      //retrieve invite records from API
      userApi.getCustomerInvites(props.match.params.id).then((resp) => {
         if (resp.invites) {
            if (resp.invites.length > 0) {
               var newCustomerInvitesObject = _.cloneDeep(resp.invites);
               if (newCustomerInvitesObject) {
                  if (newCustomerInvitesObject.length > 0) {
                     Object.keys(newCustomerInvitesObject).forEach(function (key) {
                        newCustomerInvitesObject[key].id = newCustomerInvitesObject[key]._id;
                     });

                     setSortedCustToken(_.orderBy(newCustomerInvitesObject, ["email"], ["asc"]));
                     return;
                  }
               }
            }
         }

         setSortedCustToken(null);
      });

      setLoading(false);
   }

   function revokeToken(id, email) {
      let finalRevoking = [...revokingInvite];
      finalRevoking.push(id);
      setRevokingInvite(finalRevoking);

      if (id) {
         userApi
            .deleteCustomerInvite(id)
            .then((resp) => {
               if (resp) {
                  if (resp.success) {
                     setLoadActivity(true);

                     customerApi.getCustomer(props.match.params.id).then((resp) => {
                        if (resp.customer.length > 0) {
                           var newSelectedCustomer = resp.customer[0];
                           setSelectedCustomer(newSelectedCustomer);
                        }
                     });

                     //retrieve invite records from API
                     userApi.getCustomerInvites(props.match.params.id).then((resp) => {
                        if (resp.invites) {
                           if (resp.invites.length > 0) {
                              var newCustomerInvitesObject = _.cloneDeep(resp.invites);
                              if (newCustomerInvitesObject) {
                                 if (newCustomerInvitesObject.length > 0) {
                                    Object.keys(newCustomerInvitesObject).forEach(function (key) {
                                       newCustomerInvitesObject[key].id = newCustomerInvitesObject[key]._id;
                                    });

                                    setSortedCustToken(_.orderBy(newCustomerInvitesObject, ["email"], ["asc"]));
                                    return;
                                 }
                              }
                           }
                        }

                        setSortedCustToken(null);
                     });

                     openAlertMessage(`Successful revoked token`, "success");
                  } else {
                     openAlertMessage(`Error revoking token: ${resp.message}`, "error");
                  }
               } else {
                  openAlertMessage(`Error revoking token.`, "error");
               }
            })
            .catch((err) => {
               openAlertMessage(`Error revoking token: ${err}`, "error");
            });
      }

      const revokingIndex = finalRevoking.indexOf(id);

      if (revokingIndex > -1) {
         finalRevoking = [...finalRevoking.slice(revokingIndex, -1)];
      }

      setRevokingInvite(finalRevoking);

      customerApi.getCustomer(props.match.params.id).then((resp) => {
         if (resp.customer.length > 0) {
            var newSelectedCustomer = resp.customer[0];
            setSelectedCustomer(newSelectedCustomer);
         }
      });

      //retrieve invite records from API
      userApi.getCustomerInvites(props.match.params.id).then((resp) => {
         if (resp.invites) {
            if (resp.invites.length > 0) {
               var newCustomerInvitesObject = _.cloneDeep(resp.invites);
               if (newCustomerInvitesObject) {
                  if (newCustomerInvitesObject.length > 0) {
                     Object.keys(newCustomerInvitesObject).forEach(function (key) {
                        newCustomerInvitesObject[key].id = newCustomerInvitesObject[key]._id;
                     });

                     setSortedCustToken(_.orderBy(newCustomerInvitesObject, ["email"], ["asc"]));
                     return;
                  }
               }
            }
         }

         setSortedCustToken(null);
      });

      setLoading(false);
   }

   function deleteUser() {
      setDeleteModal(!deleteModal);

      userApi
         .deleteUser(selectedUser._id)
         .then((resp) => {
            openAlertMessage("User Successfully Deleted.", "success");

            //update original record in customerUsers
            getCustomerUsers();

            //Refresh activity
            setLoadActivity(true);
         })
         .catch((err) => openAlertMessage(`Error deleting user: ${err}`, "error"));
   }

   function extendRates() {
      setExtendRatesModal(!extendRatesModal);

      rateApi
         .extendRates("", props.match.params.id, props.user._id, extendDate)
         .then((resp) => {
            openAlertMessage(
               "Customer Rate Extension has started. You will receive a notification and email once it has completed",
               "success",
            );
         })
         .catch((err) => openAlertMessage(`Error extending rates: ${err}`, "error"));
   }

   function expireRates() {
      setExpireRatesModal(!expireRatesModal);

      rateApi
         .expireRates("", props.match.params.id, props.user._id, expireDate)
         .then((resp) => {
            openAlertMessage(
               "Customer Rate Expiration has started. You will receive a notification and email once it has completed",
               "success",
            );
         })
         .catch((err) => openAlertMessage(`Error expiring rates: ${err}`, "error"));
   }

   function getCustomerUsers() {
      //retrieve record from API
      userApi.getUsers(null, props.match.params.id, false).then((resp) => {
         if (resp.users) {
            if (resp.users.length > 0) {
               var newUsersCustomerObject = _.cloneDeep(resp.users);

               if (newUsersCustomerObject) {
                  if (newUsersCustomerObject.length > 0) {
                     Object.keys(newUsersCustomerObject).forEach(function (key) {
                        newUsersCustomerObject[key].id = newUsersCustomerObject[key]._id;
                     });

                     setCustomerUsers(newUsersCustomerObject);
                     setGridLoading(false);
                     return;
                  }
               }
            }
         }

         setCustomerUsers([]);
         setGridLoading(false);
      });
   }

   async function exportRates() {
      setExportingRates(true);

      await rateApi
         .exportRates(null, props.match.params.id, props.user._id)
         .then(() => {
            //success
            openAlertMessage(
               "Customer Rate Export has started. You will receive a notification and email once it has completed",
               "success",
            );

            setExportingRates(false);

            //Refresh activity
            setLoadActivity(true);
         })
         .catch((err) => {
            openAlertMessage(`${err}`, "error");
            setExportingRates(false);

            //Refresh activity
            setLoadActivity(true);
         });
   }

   const handleTabsChange = (event, newValue) => {
      setTabsValue(newValue);
   };

   function a11yProps(index) {
      return {
         id: `simple-tab-${index}`,
         "aria-controls": `simple-tabpanel-${index}`,
      };
   }

   function breadcrumbOnClick(path) {
      if (path) {
         history.push(path);
      }
   }

   function checkExtendButtonSecurity() {
      if (!props.user) {
         setExtendButtonDisabled(true);
         return;
      }

      if (!props.user.security.admin) {
         setExtendButtonDisabled(true);
         return;
      }

      if (!extendDate) {
         setExtendButtonDisabled(true);
         return;
      }

      if (!selectedCustomer) {
         setExtendButtonDisabled(true);
         return;
      }

      var d = new Date();
      d.setHours(0, 0, 0, 0);

      var extendDateFormatted = new Date(extendDate);

      var extendDateUtc = new Date(
         extendDateFormatted.getUTCFullYear(),
         extendDateFormatted.getUTCMonth(),
         extendDateFormatted.getUTCDate(),
         extendDateFormatted.getUTCHours(),
         extendDateFormatted.getUTCMinutes(),
         extendDateFormatted.getUTCSeconds(),
      );

      if (extendDateUtc.getTime() < d.getTime()) {
         setExtendButtonDisabled(true);
         return;
      }

      setExtendButtonDisabled(false);
   }

   function checkExpireButtonSecurity() {
      if (!props.user) {
         setExpireButtonDisabled(true);
         return;
      }

      if (!props.user.security.admin) {
         setExpireButtonDisabled(true);
         return;
      }

      if (!expireDate) {
         setExpireButtonDisabled(true);
         return;
      }

      if (!selectedCustomer) {
         setExpireButtonDisabled(true);
         return;
      }

      var d = new Date();
      d.setHours(0, 0, 0, 0);

      var expireDateFormatted = new Date(expireDate);

      var expireDateUtc = new Date(
         expireDateFormatted.getUTCFullYear(),
         expireDateFormatted.getUTCMonth(),
         expireDateFormatted.getUTCDate(),
         expireDateFormatted.getUTCHours(),
         expireDateFormatted.getUTCMinutes(),
         expireDateFormatted.getUTCSeconds(),
      );

      if (expireDateUtc.getTime() >= d.getTime()) {
         setExpireButtonDisabled(true);
         return;
      }

      setExpireButtonDisabled(false);
   }

   const classes = useStyles();

   return (
      <>
         <div id={"customerDetails"} className={classes.layoutMain}>
            <div id={"customerDetailsHeader"} className={classes.secondaryHeader}>
               <Grid container direction="rows" justifyContent="space-between" alignItems="center">
                  <Grid item xs={6}>
                     {!loading ? (
                        <Breadcrumbs style={{ paddingLeft: "1.5rem" }} separator={<NavigateNextIcon />}>
                           <Link
                              color="inherit"
                              style={{ cursor: "pointer" }}
                              onClick={() => breadcrumbOnClick("/customer")}
                           >
                              <h3 style={{ fontWeight: "500" }}>{"Customers"}</h3>
                           </Link>
                           <Link
                              color="inherit"
                              style={{ cursor: "pointer" }}
                              onClick={() => breadcrumbOnClick("/customer/details/" + props.match.params.id)}
                           >
                              <h3 style={{ fontWeight: "500" }}>
                                 {selectedCustomer.name ? selectedCustomer.name : null}
                              </h3>
                           </Link>
                        </Breadcrumbs>
                     ) : null}
                  </Grid>
                  <Grid item xs={6}>
                     <Tooltip title="Export Customer Rates" arrow>
                        <IconButton
                           style={{ marginRight: "1rem", float: "right" }}
                           variant="contained"
                           color="primary"
                           onClick={exportRates}
                           disabled={props.user.security.admin ? false : true}
                        >
                           {exportingRates ? (
                              <CircularProgress size={32} className={classes.buttonProgress} />
                           ) : (
                              <ImportExportIcon style={{ fontSize: "2rem" }} />
                           )}
                        </IconButton>
                     </Tooltip>
                     <Tooltip title="Save" arrow>
                        <IconButton
                           className={classes.save}
                           variant="contained"
                           color="primary"
                           onClick={() => updateCust()}
                           disabled={readOnly}
                        >
                           <SaveIcon style={{ fontSize: "2rem" }} />
                        </IconButton>
                     </Tooltip>
                  </Grid>
               </Grid>
               <Collapse in={showAlert}>
                  <Alert
                     style={{ color: "#FFFFFF" }}
                     variant="filled"
                     severity={alertType ? alertType : "success"}
                     action={
                        <IconButton
                           aria-label="close"
                           color="inherit"
                           size="small"
                           onClick={() => {
                              {
                                 closeAlertMessage();
                              }
                           }}
                        >
                           <CloseIcon fontSize="inherit" />
                        </IconButton>
                     }
                  >
                     {alertMessage}
                  </Alert>
               </Collapse>
            </div>
            <div id={"customerDetailsBody"} className={showAlert === true ? classes.layoutBody2 : classes.layoutBody}>
               {loading ? (
                  <div className="loader-wrapper">
                     <div className="truck-wrapper">
                        <div className="truck">
                           <div className="truck-container">
                              <img
                                 style={{ width: "65px", paddingLeft: "5px", paddingTop: "15px" }}
                                 src={containerLogo}
                              />
                           </div>
                           <div className="glases"></div>
                           <div className="bonet"></div>
                           <div className="base"></div>
                           <div className="base-aux"></div>
                           <div className="wheel-back"></div>
                           <div className="wheel-front"></div>
                           <div className="smoke"></div>
                        </div>
                     </div>
                  </div>
               ) : (
                  <>
                     <Grid container direction="row" justifyContent="space-between">
                        <Grid item xs={12}>
                           <Grid container direction="column" justifyContent="space-between" alignItems="center">
                              <Grid item sx={{ width: "100%" }}>
                                 <Box p="1%" width="100%">
                                    <Paper elevation={3}>
                                       <AppBar
                                          position="static"
                                          color="default"
                                          className={classes.appBar}
                                          sx={{
                                             boxShadow: "none",
                                             borderBottom: "1px solid black",
                                          }}
                                       >
                                          <Tabs
                                             indicatorColor="primary"
                                             textColor="primary"
                                             onChange={handleTabsChange}
                                             value={tabsValue}
                                             aria-label="tabs"
                                          >
                                             <Tab
                                                sx={{
                                                   fontSize: "1rem",
                                                   fontWeight: "700",
                                                   letterSpacing: "0px",
                                                   textTransform: "none",
                                                }}
                                                label="Customer Details"
                                                {...a11yProps(0)}
                                             />
                                             <Tab
                                                sx={{
                                                   fontSize: "1rem",
                                                   fontWeight: "700",
                                                   letterSpacing: "0px",
                                                   textTransform: "none",
                                                }}
                                                label="Activity"
                                                {...a11yProps(1)}
                                             />
                                          </Tabs>
                                       </AppBar>
                                       {tabsValue === 0 && (
                                          <Box sx={{ padding: "0 2rem 1rem 0" }}>
                                             <Grid container direction={"row"} justifyContent="space-between">
                                                <Grid item xs={6}>
                                                   <Grid
                                                      container
                                                      direction={"column"}
                                                      justifyContent="space-between"
                                                      alignItems="center"
                                                   >
                                                      <Grid item style={{ width: "100%" }}>
                                                         <div style={{ padding: "1%", width: "100%" }}>
                                                            <Paper elevation={3} style={{ padding: "3%" }}>
                                                               <form>
                                                                  <Typography
                                                                     variant="h3"
                                                                     component="h3"
                                                                     sx={{
                                                                        textAlign: "center",
                                                                        fontSize: "1rem",
                                                                        fontWeight: 700,
                                                                        m: "16.38px 0",
                                                                     }}
                                                                  >
                                                                     Customer Info
                                                                  </Typography>
                                                                  <TextField
                                                                     error={
                                                                        selectedCustomer.name.length > 0 ? false : true
                                                                     }
                                                                     id={
                                                                        selectedCustomer.name.length > 0
                                                                           ? "standar-basic"
                                                                           : "outlined-error-helper-text"
                                                                     }
                                                                     style={{ marginRight: "1rem" }}
                                                                     label="Name"
                                                                     helperText={
                                                                        selectedCustomer.name.length === 0 &&
                                                                        "Required Field"
                                                                     }
                                                                     variant="standard"
                                                                     name="name"
                                                                     value={selectedCustomer.name}
                                                                     onChange={onChange}
                                                                     disabled={readOnly}
                                                                     required
                                                                  />
                                                                  <TextField
                                                                     error={
                                                                        selectedCustomer.code.length > 0 ? false : true
                                                                     }
                                                                     id={
                                                                        selectedCustomer.code.length > 0
                                                                           ? "standar-basic"
                                                                           : "outlined-error-helper-text"
                                                                     }
                                                                     style={{ marginRight: "1rem" }}
                                                                     label="Master Account"
                                                                     helperText={
                                                                        selectedCustomer.code.length === 0 &&
                                                                        "Required Field"
                                                                     }
                                                                     variant="standard"
                                                                     name="code"
                                                                     value={selectedCustomer.code}
                                                                     onChange={onChange}
                                                                     disabled={readOnly}
                                                                     required
                                                                  />
                                                                  <TextField
                                                                     id="standard-basic"
                                                                     style={{ marginRight: "1rem" }}
                                                                     label="Address 1"
                                                                     variant="standard"
                                                                     name="address1"
                                                                     value={selectedCustomer.address.address1}
                                                                     onChange={onChangeAddress}
                                                                     disabled={readOnly}
                                                                  />
                                                                  <TextField
                                                                     id="standard-basic"
                                                                     style={{ marginRight: "1rem" }}
                                                                     label="Address 2"
                                                                     variant="standard"
                                                                     name="address2"
                                                                     value={selectedCustomer.address.address2}
                                                                     onChange={onChangeAddress}
                                                                     disabled={readOnly}
                                                                  />
                                                                  <TextField
                                                                     id="standard-basic"
                                                                     style={{ marginRight: "1rem" }}
                                                                     label="City"
                                                                     variant="standard"
                                                                     name="city"
                                                                     value={selectedCustomer.address.city}
                                                                     onChange={onChangeAddress}
                                                                     disabled={readOnly}
                                                                  />
                                                                  <FormControl
                                                                     style={{ width: "5rem" }}
                                                                     variant="standard"
                                                                  >
                                                                     <InputLabel id="state-simple-select-label">
                                                                        State
                                                                     </InputLabel>
                                                                     <Select
                                                                        labelId="state-simple-select-label"
                                                                        id="state-simple-select"
                                                                        value={selectedCustomer.address.state}
                                                                        name="state"
                                                                        onChange={(e) => onChangeAddress(e)}
                                                                        disabled={readOnly}
                                                                        style={{ marginRight: "1rem" }}
                                                                     >
                                                                        <MenuItem value={null}>Choose...</MenuItem>
                                                                        {stateList.map((state, index) => {
                                                                           return (
                                                                              <MenuItem
                                                                                 key={`mi-${index}`}
                                                                                 value={state}
                                                                              >
                                                                                 {state}
                                                                              </MenuItem>
                                                                           );
                                                                        })}
                                                                     </Select>
                                                                  </FormControl>
                                                                  <TextField
                                                                     id="standard-basic"
                                                                     style={{ marginRight: "1rem" }}
                                                                     label="Zip"
                                                                     variant="standard"
                                                                     name="zip"
                                                                     value={selectedCustomer.address.zip}
                                                                     onChange={onChangeAddress}
                                                                     disabled={readOnly}
                                                                  />
                                                                  <FormControlLabel
                                                                     style={{ marginTop: "1rem" }}
                                                                     control={
                                                                        <Switch
                                                                           name="active"
                                                                           checked={selectedCustomer.active}
                                                                           onClick={onChange}
                                                                           color="primary"
                                                                           disabled={readOnly}
                                                                        />
                                                                     }
                                                                     label="Active"
                                                                  />
                                                                  <div>
                                                                     <TextField
                                                                        id="standard-basic"
                                                                        style={{
                                                                           marginRight: "1rem",
                                                                           width: "100%",
                                                                           maxWidth: "25.375rem",
                                                                        }}
                                                                        label="PDF Footer Email"
                                                                        variant="standard"
                                                                        name="pdfEmail"
                                                                        value={selectedCustomer.pdfEmail}
                                                                        onChange={onChange}
                                                                        disabled={readOnly}
                                                                     />
                                                                  </div>
                                                                  <Box sx={{ width: "100%", marginTop: "1rem" }}>
                                                                     <Button
                                                                        sx={{
                                                                           display: "inline-flex",
                                                                           flexDirection: "column",
                                                                           alignItems: "center",
                                                                           fontSize: "11px",
                                                                           marginLeft: "20px",
                                                                           width: "180px",
                                                                           height: "65px",
                                                                           lineHeight: "13px",
                                                                        }}
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={() => setCustomerFuelTariffModal(true)}
                                                                        disabled={readOnly}
                                                                     >
                                                                        <EvStationIcon
                                                                           sx={{ marginBottom: "0.1rem" }}
                                                                        />
                                                                        Edit Customer Specific Fuel Tariffs
                                                                     </Button>
                                                                     <Button
                                                                        sx={{
                                                                           display: "inline-flex",
                                                                           flexDirection: "column",
                                                                           alignItems: "center",
                                                                           fontSize: "11px",
                                                                           marginLeft: "20px",
                                                                           width: "180px",
                                                                           height: "65px",
                                                                           lineHeight: "13px",
                                                                        }}
                                                                        variant="contained"
                                                                        color="primary"
                                                                        onClick={handleEditSpecificAccessorials}
                                                                        disabled={readOnly}
                                                                     >
                                                                        <AssignmentIcon
                                                                           sx={{ marginBottom: "0.1rem" }}
                                                                        />
                                                                        Edit Customer Specific Accessorials
                                                                     </Button>
                                                                  </Box>
                                                               </form>
                                                            </Paper>
                                                         </div>
                                                      </Grid>
                                                      <Grid
                                                         item
                                                         style={{ width: "100%", display: readOnly ? "none" : null }}
                                                      >
                                                         <div style={{ padding: "1%", width: "100%" }}>
                                                            <Paper
                                                               style={{
                                                                  padding: "3%",
                                                                  height: "fit-content",
                                                                  width: "100%",
                                                               }}
                                                            >
                                                               <h5>Upload</h5>
                                                               {selectedCustomer._id !== null ? (
                                                                  <div style={{ padding: "1%" }}>
                                                                     <FileUpload
                                                                        directory="customers"
                                                                        share="rates"
                                                                        termID={null}
                                                                        custID={selectedCustomer._id}
                                                                        userID={props.user._id}
                                                                        openAlertMessage={openAlertMessage}
                                                                     />
                                                                  </div>
                                                               ) : (
                                                                  <div style={{ padding: "1%" }}>
                                                                     Please Select Customer
                                                                  </div>
                                                               )}
                                                            </Paper>
                                                         </div>
                                                      </Grid>
                                                      <Grid
                                                         container
                                                         style={{ width: "100%", display: readOnly ? "none" : null }}
                                                         justifyContent="space-between"
                                                         alignItems="center"
                                                      >
                                                         <div style={{ padding: "1%", width: "100%" }}>
                                                            <Paper
                                                               style={{
                                                                  padding: "3%",
                                                                  height: "fit-content",
                                                                  width: "100%",
                                                               }}
                                                            >
                                                               <h5>Extend/Expire Customer Rates</h5>
                                                               <TextField
                                                                  id="extendDate"
                                                                  label="Extend Rates to Date"
                                                                  type="date"
                                                                  value={extendDate}
                                                                  onChange={(event) =>
                                                                     setExtendDate(
                                                                        event.target.value ? event.target.value : null,
                                                                     )
                                                                  }
                                                                  InputLabelProps={{
                                                                     shrink: true,
                                                                  }}
                                                                  variant="outlined"
                                                                  style={{ marginRight: "1rem" }}
                                                                  disabled={!props.user || !props.user.security.admin}
                                                               />
                                                               <Button
                                                                  sx={{
                                                                     display: "inline-flex",
                                                                     flexDirection: "column",
                                                                     alignItems: "center",
                                                                     fontSize: "11px",
                                                                     height: "55px",
                                                                     lineHeight: "13px",
                                                                     marginRight: "1rem",
                                                                  }}
                                                                  variant="contained"
                                                                  color="primary"
                                                                  onClick={() => setExtendRatesModal(true)}
                                                                  disabled={extendButtonDisabled}
                                                               >
                                                                  <DateRangeIcon sx={{ marginBottom: "0.1rem" }} />
                                                                  Extend Rates
                                                               </Button>
                                                               <TextField
                                                                  id="expireDate"
                                                                  label="Expire Rates on Date"
                                                                  type="date"
                                                                  value={expireDate}
                                                                  onChange={(event) =>
                                                                     setExpireDate(
                                                                        event.target.value ? event.target.value : null,
                                                                     )
                                                                  }
                                                                  InputLabelProps={{
                                                                     shrink: true,
                                                                  }}
                                                                  variant="outlined"
                                                                  style={{ marginRight: "1rem" }}
                                                                  disabled={!props.user || !props.user.security.admin}
                                                               />
                                                               <Button
                                                                  sx={{
                                                                     display: "inline-flex",
                                                                     flexDirection: "column",
                                                                     alignItems: "center",
                                                                     fontSize: "11px",
                                                                     height: "55px",
                                                                     lineHeight: "13px",
                                                                  }}
                                                                  variant="contained"
                                                                  color="primary"
                                                                  onClick={() => setExpireRatesModal(true)}
                                                                  disabled={expireButtonDisabled}
                                                               >
                                                                  <DateRangeIcon sx={{ marginBottom: "0.1rem" }} />
                                                                  Expire Rates
                                                               </Button>
                                                            </Paper>
                                                         </div>
                                                      </Grid>
                                                   </Grid>
                                                </Grid>
                                                <Grid item xs={6}>
                                                   <div style={{ padding: "1%", width: "100%" }}>
                                                      <Paper elevation={3} style={{ padding: "1%" }}>
                                                         <Grid
                                                            container
                                                            direction={"row"}
                                                            xs={12}
                                                            display="flex"
                                                            alignItems="center"
                                                         >
                                                            <Grid item xs={6}>
                                                               <Tooltip title="Invite New User(s)" arrow>
                                                                  <IconButton
                                                                     id="btn-email"
                                                                     variant="contained"
                                                                     color="primary"
                                                                     style={{ marginLeft: "1%", float: "right" }}
                                                                     disabled={readOnly}
                                                                     onClick={() => setCustomerEmailInviteModal(true)}
                                                                  >
                                                                     <EmailRoundedIcon
                                                                        style={{ fontSize: "2rem" }}
                                                                     ></EmailRoundedIcon>
                                                                  </IconButton>
                                                               </Tooltip>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                               <Typography
                                                                  variant="h5"
                                                                  component="h5"
                                                                  sx={{
                                                                     textAlign: "left",
                                                                     fontSize: "0.75rem",
                                                                     fontWeight: 700,
                                                                     m: "19.405px 0 16px 0",
                                                                     letterSpacing: 0,
                                                                  }}
                                                               >
                                                                  Users Associated
                                                               </Typography>
                                                            </Grid>
                                                         </Grid>
                                                         <StyledDataGrid
                                                            gridHeight={"264px"}
                                                            loading={gridLoading}
                                                            columns={[
                                                               {
                                                                  field: "email",
                                                                  headerName: "Email",
                                                                  description: `User's email.`,
                                                                  type: "string",
                                                                  filterable: false,
                                                                  flex: 4,
                                                               },
                                                               {
                                                                  field: "_id",
                                                                  headerName: " ", //This is a space because material-UI default to field unless there is a value.
                                                                  type: "string",
                                                                  sortable: false,
                                                                  filterable: false,
                                                                  renderCell: (params) => (
                                                                     <div>
                                                                        <Tooltip title="Edit User" arrow>
                                                                           <IconButton
                                                                              variant="contained"
                                                                              color="primary"
                                                                              size="small"
                                                                              onClick={() => handleEditClick(params)}
                                                                              disabled={readOnly}
                                                                           >
                                                                              <EditIcon />
                                                                           </IconButton>
                                                                        </Tooltip>
                                                                        |
                                                                        <Tooltip title="Delete User" arrow>
                                                                           <IconButton
                                                                              variant="contained"
                                                                              color="secondary"
                                                                              size="small"
                                                                              onClick={() => handleDeleteClick(params)}
                                                                              disabled={readOnly}
                                                                           >
                                                                              <DeleteForeverIcon />
                                                                           </IconButton>
                                                                        </Tooltip>
                                                                     </div>
                                                                  ),
                                                                  flex: 0.5,
                                                               },
                                                            ]}
                                                            rows={customerUsers ? customerUsers : null}
                                                         ></StyledDataGrid>
                                                      </Paper>
                                                   </div>

                                                   <Grid item style={{ width: "100%" }}>
                                                      <div style={{ padding: "1%", width: "100%" }}>
                                                         <Paper
                                                            style={{
                                                               padding: "3%",
                                                               height: "fit-content",
                                                               width: "100%",
                                                            }}
                                                         >
                                                            <Typography
                                                               variant="h2"
                                                               component="h2"
                                                               sx={{
                                                                  fontSize: "1.3rem",
                                                                  fontWeight: 700,
                                                                  m: "17.43px 0",
                                                               }}
                                                            >
                                                               Invited Users
                                                            </Typography>
                                                            <div
                                                               style={{
                                                                  height: "50vh",
                                                                  overflow: "scroll",
                                                                  overflowX: "hidden",
                                                               }}
                                                            >
                                                               <TableContainer>
                                                                  <Table
                                                                     aria-label="simple table"
                                                                     style={{ marginBottom: "1.5rem" }}
                                                                  >
                                                                     <TableHead>
                                                                        <TableRow>
                                                                           <TableCell>Email</TableCell>
                                                                           <TableCell>Invite token</TableCell>
                                                                           <TableCell> </TableCell>
                                                                        </TableRow>
                                                                     </TableHead>
                                                                     <TableBody>
                                                                        {sortedCustToken ? (
                                                                           sortedCustToken.map((sc) => {
                                                                              return (
                                                                                 <TableRow key={`tr-${sc._id}`}>
                                                                                    <TableCell>{sc.email}</TableCell>
                                                                                    <TableCell>
                                                                                       {sc.inviteToken
                                                                                          ? "Pending"
                                                                                          : "Accepted"}
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                       <ButtonGroup aria-label="text primary button group">
                                                                                          <Tooltip
                                                                                             title="Resend Invite"
                                                                                             arrow
                                                                                          >
                                                                                             <IconButton
                                                                                                disabled={
                                                                                                   props.user.security
                                                                                                      .admin
                                                                                                      ? resendingInvite.includes(
                                                                                                           sc._id,
                                                                                                        )
                                                                                                         ? true
                                                                                                         : false
                                                                                                      : true
                                                                                                }
                                                                                                variant="contained"
                                                                                                color="primary"
                                                                                                onClick={() =>
                                                                                                   resendInvite(
                                                                                                      sc._id,
                                                                                                      selectedCustomer.name,
                                                                                                      sc.email,
                                                                                                   )
                                                                                                }
                                                                                             >
                                                                                                {resendingInvite.includes(
                                                                                                   sc._id,
                                                                                                ) ? (
                                                                                                   <CircularProgress
                                                                                                      size={18}
                                                                                                      className={
                                                                                                         classes.buttonProgress
                                                                                                      }
                                                                                                   />
                                                                                                ) : (
                                                                                                   <RefreshIcon />
                                                                                                )}
                                                                                             </IconButton>
                                                                                          </Tooltip>
                                                                                          <Tooltip
                                                                                             title="Revoke Token"
                                                                                             arrow
                                                                                          >
                                                                                             <IconButton
                                                                                                disabled={
                                                                                                   props.user.security
                                                                                                      .admin
                                                                                                      ? revokingInvite.includes(
                                                                                                           sc._id,
                                                                                                        )
                                                                                                         ? true
                                                                                                         : false
                                                                                                      : true
                                                                                                }
                                                                                                loadNewtoken={
                                                                                                   loadNewtoken
                                                                                                }
                                                                                                setLoadNewtoken={() =>
                                                                                                   setLoadNewToken(
                                                                                                      false,
                                                                                                   )
                                                                                                }
                                                                                                color="secondary"
                                                                                                variant="contained"
                                                                                                onClick={() =>
                                                                                                   revokeToken(
                                                                                                      sc._id,
                                                                                                      sc.email,
                                                                                                   )
                                                                                                }
                                                                                             >
                                                                                                {revokingInvite.includes(
                                                                                                   sc._id,
                                                                                                ) ? (
                                                                                                   <CircularProgress
                                                                                                      size={18}
                                                                                                      className={
                                                                                                         classes.buttonProgress
                                                                                                      }
                                                                                                   />
                                                                                                ) : (
                                                                                                   <DeleteForeverIcon />
                                                                                                )}
                                                                                             </IconButton>
                                                                                          </Tooltip>
                                                                                       </ButtonGroup>
                                                                                    </TableCell>
                                                                                 </TableRow>
                                                                              );
                                                                           })
                                                                        ) : (
                                                                           <TableRow />
                                                                        )}
                                                                     </TableBody>
                                                                  </Table>
                                                               </TableContainer>
                                                            </div>
                                                         </Paper>
                                                      </div>
                                                   </Grid>
                                                </Grid>
                                             </Grid>
                                          </Box>
                                       )}
                                       {tabsValue === 1 && (
                                          <Box p={3}>
                                             <ActivityLogGrid
                                                columns={[
                                                   {
                                                      field: "date",
                                                      headerName: "Date",
                                                      description: "Date",
                                                      type: "date",
                                                      flex: 0.1,
                                                   },
                                                   {
                                                      field: "hour",
                                                      headerName: "Hour",
                                                      description: "Hour",
                                                      type: "time",
                                                      flex: 0.1,
                                                   },

                                                   {
                                                      field: "event",
                                                      headerName: "Event",
                                                      description: "Event",
                                                      type: "string",
                                                      flex: 0.2,
                                                   },
                                                   {
                                                      field: "description",
                                                      headerName: "Event Description",
                                                      description: "Event Description",
                                                      type: "string",
                                                      flex: 0.6,
                                                   },
                                                ]}
                                                tableData={activityTableData}
                                             />
                                          </Box>
                                       )}
                                    </Paper>
                                 </Box>
                              </Grid>
                           </Grid>
                        </Grid>
                     </Grid>
                  </>
               )}
               <DeleteDialog
                  open={deleteModal}
                  handleCancel={() => setDeleteModal(!deleteModal)}
                  deleteFunc={deleteUser}
                  title={selectedUser && `Delete ${selectedUser.email}`}
                  text={selectedUser && `Are you sure you want to delete ${selectedUser.email} from customer?`}
               />
               <ExtendRatesDialog
                  open={extendRatesModal}
                  handleCancel={() => setExtendRatesModal(!extendRatesModal)}
                  extendRatesFunc={extendRates}
                  title={`Extend Rates For ${selectedCustomer ? selectedCustomer.name : "Customer"}`}
                  text={`You are about to extend all rates for all lanes for ${
                     selectedCustomer ? selectedCustomer.name : "the customer"
                  } ending on ${dateFormatter(extendDate)}. Do you wish to continue?`}
               />
               <ExpireRatesDialog
                  open={expireRatesModal}
                  handleCancel={() => setExpireRatesModal(!expireRatesModal)}
                  expireRatesFunc={expireRates}
                  title={`Expire Rates For ${selectedCustomer ? selectedCustomer.name : "Customer"}`}
                  text={`You are about to expire all rates for all lanes for ${
                     selectedCustomer ? selectedCustomer.name : "the customer"
                  } ending on ${dateFormatter(expireDate)}. Do you wish to continue?`}
               />
               <CustomerEmailInvite
                  open={customerEmailInviteModal}
                  handleClose={() => setCustomerEmailInviteModal(!customerEmailInviteModal)}
                  openAlertMessage={openAlertMessage}
                  customerName={selectedCustomer ? selectedCustomer.name : null}
                  customerId={props.match.params.id}
                  userId={props.user._id}
               />
               <CustomerFuelTariff
                  open={customerFuelTariffModal}
                  handleClose={() => setCustomerFuelTariffModal(!customerFuelTariffModal)}
                  openAlertMessage={openAlertMessage}
                  selectedCustomer={selectedCustomer}
                  setSelectedCustomer={setSelectedCustomer}
                  userId={props.user._id}
                  customerFuelTariffModal={customerFuelTariffModal}
                  setLoadActivity={setLoadActivity}
               />
            </div>
         </div>
      </>
   );
};

function mapStateToProps(state) {
   return {
      customers: state.customer !== null ? state.customer.customer : [],
      user: state.user.currentUser,
      accessorialTariffs: state.accessorial.tariff || [],
   };
}

function mapDispatchToProps(dispatch) {
   return {
      getCustomers: () => dispatch(customerActions.getCustomers()),
   };
}

CustomerDetails.propTypes = {
   match: PropTypes.shape({
      params: PropTypes.shape({
         id: PropTypes.string.isRequired,
      }),
   }),
   accessorialTariffs: PropTypes.array.isRequired,
   customers: PropTypes.array.isRequired,
   user: PropTypes.object.isRequired,
   getCustomers: PropTypes.func.isRequired,
};

CustomerDetails.defaultProps = {
   match: { params: { id: "" } },
   accessorialTariffs: [],
   customers: [],
   user: {},
   getCustomers: () => {
      return;
   },
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetails);
